import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home.component";
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CorouselComponent } from "./corousel/corousel.component";

const route: Routes = [
    { path: '', component: HomeComponent }
]

@NgModule({
    declarations: [
        CorouselComponent,
        HomeComponent
    ],
    imports: [
        RouterModule.forChild(route),
        CarouselModule.forRoot(),
        
    ],
    exports:[RouterModule],
    providers: []
})
export class HomeModule { }

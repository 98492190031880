import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';


const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: ' ', loadChildren: './home/home.module#HomeModule' },
    { path: 'about-us', loadChildren:'./about-us/about-us.module#AboutUsModule'},
    { path: 'careers', loadChildren:'./careers/careers.module#CareersModule' },
    { path: 'services', loadChildren:'./services/service.module#ServiceModule' },
    { path: 'support', loadChildren:'./support/support.module#SupportModule' },
    { path: 'products', loadChildren: './products/product.module#ProductModule'},
    { path: 'contact-us', loadChildren: './contact-us/contact-us.module#ContactUsModule'},
];



@NgModule({
    imports: [RouterModule.forRoot(routes, {preloadingStrategy:PreloadAllModules})],
    exports: [RouterModule],
    declarations: []
})

export class AppRouting { }

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRouting } from "./app-routing.module";
import { NavBarComponent } from "./shared/nav-bar/nav-bar.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { ClickOutsideDirective } from './shared/click-outside.directive';
import { HomeModule } from './home/home.module';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CarouselModule } from 'ngx-bootstrap/carousel';



@NgModule({
    declarations: [
        AppComponent,
        NavBarComponent,
        FooterComponent,
        ClickOutsideDirective,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HomeModule,
        AppRouting,
        AlertModule.forRoot(),
        CarouselModule.forRoot(),
    ],

    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, HostListener} from '@angular/core';
import { Router } from "@angular/router";
import { ClickOutsideDirective } from '../../shared/click-outside.directive';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
 

 
})

export class NavBarComponent implements OnInit {
  isCollapsed = false;
  public isScrolled = false;
  public isResized = false;
  private active:boolean = false;
  menu:boolean = false;
  opened: boolean = false;
  constructor(private router: Router ) {}

  toggleNavbar(){
    this.isCollapsed = !this.isCollapsed;
   
  }


  
  onClickOutside(event:Object) {
    if(event && event['value'] === true) {
        console.log("Nav bar clicked");
      this.active = false;
    }
  }
  ngOnInit(): void {
    this.onWindowScroll();
}
  
@HostListener('window:scroll', [])
onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || window.scrollY || 0;
    if (number > 0 ) {
      this.isResized = true;
        this.isScrolled = true;
    } else if (this.isScrolled && number < 300) {
        this.isScrolled = false;
        this.isResized = false;
    }
}


}
